import {createFromInput} from "./html/forms";
import {collectData} from "./invoices";
import {FileMan} from "./fileManager";
import AccordionClass from "./html/accordion";

export default class EstimatesClass {
    data
    hideEmpty = false

    init(data) {
        console.log('built', data)

        let c = $('#'+CS.currentContextID())
        this.container = $('.modal-body', c)
        this.container.html('')

        this.data = data
        if(this.data.data)
            this.hideEmpty = true

        this.initGUI()


    }

    initGUI(){
        // let c = $('<div>').addClass('row dataRow')
        let cont = $('<div>').addClass('EstimatesContainer')
        let acc = new AccordionClass()
        acc.lClass('box-sum')
        for(let x1 in this.data.supportingData){
            let td = this.data.supportingData[x1]

            let head = $('<div>').addClass('card-header hstack w-100') //.html(x1)
                .append(
                    $('<span>').addClass('').html(x1),
                    $('<span>').addClass('box-sum-out ms-auto').html('aa')
                )
            let body = $('<div>').addClass('card-body')

            $(td).each((a,i) => {
                let x = {'type':'currency','name':i.id,'inputClass':'countSum','value':''}
                let d = {'type':'text','name':i.id+'d','inputClass':'','value':'', 'containerClass':'w-50'}
                if(this.data.data) {
                    x.value = this.data.data[i.id]
                    if(this.data.data.descr){
                        d.value = this.data.data.descr[i.id]

                    }
                }
                let line = $('<div>').addClass('line hstack dataRow')
                    .append(
                        $('<span>').addClass('me-auto mt-2').html(i.Name),
                        createFromInput(x),
                        createFromInput(d),
                    )
                body.append(line)
            })
            acc.add(head,body)

            // card.append(head, body)

            // cont.append(card)
        }

        cont.append(acc.html())

        $('#attach').html(
            $('<span>')
                .addClass('h-stack')
                .append(
                    $('<input type="checkbox">').on('click', () => { this.toggleEmpties() }),
                    'Empties'
                )
        )


        let total = $('<div>').addClass('w-100 text-center').html('Total')
            .append(
                $('<span>').attr('id','total').addClass('ms-5')
            )

        this.container.append(cont, total)
        CS.updateTotals()
        CS.updateSums()

        this.refresh()
    }

    toggleEmpties() {
        this.hideEmpty = !this.hideEmpty

        this.refresh()
    }

    refresh(){
        $('.box-sum').each( (k,t) => {
            if($($('.box-sum-out', t)[0]).html() == '$0.00'){
                if(this.hideEmpty) $(t).hide()
                else $(t).show()
            }
        })
        $('.dataRow').each( (k,t) => {
            if($($('.countSum', t)[0]).val() == ''){
                if(this.hideEmpty) $(t).hide()
                else $(t).show()
            }
        })


    }


    save(){
        let data = {... this.saveData, ... collectData(this.container)}

        console.log(data)

        if(this.saving){
            CS.showInfo('Already Saving...')
            return
        }
        let n = CS.showInfo('Saving...')

        this.saving = true

        setTimeout(()=>{
            this.saving = false
        },5000)


        CS.clearTrackedChanges()

        CS.makeCall({
            url: route('subsheet.save'),
            data: JSON.stringify(data),
            contentType: 'application/json'
        }, (r) => {
            // CS.showSuccess('Saved!')
            // CS.showErrors(out.errors)
            n.hide()
            this.handleResponse(r)
            this.saving = false
        })

    }

    handleResponse(r){
        if(r.reload)
            CS.reloadSubsheet()
    }



}
